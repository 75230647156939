<template>
  <!--begin::Mixed Widget 18-->
  <div class="card card-custom gutter-b" style="min-height:343px">
    <!--begin::Header-->
    <div class="card-header border-0 my-lg-0">
      <div class="card-title">
        <div class="card-label font-weight-bolder">
          {{ $t('dashboard.ordered_passes.title') }}
        </div>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body Active-->
    <div v-if="passes.length > 0 || !loaded" class="card-body d-flex flex-column pt-0">
      <!--begin::Items-->
      <!--begin::Widget Item-->
      <div v-if="loaded" style="max-height: 284px; overflow-y: auto;">
        <div class="d-flex flex-nowrap align-items-center overflow-hidden mb-5" v-for="item in passes.slice(0, 2)" :key="item.id">
          <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
            <div>{{ item.type === 1 ? $t('dashboard.ordered_passes.active_one') :  $t('dashboard.ordered_passes.active_two') }}</div>
            <div>{{ getLocationName(item.location_id) }}</div>
            <div>{{ $t('dashboard.ordered_passes.active_until') }} {{ 1 | formatDateUntilTo(item.ts_end, getTimezone(item.location_id)) }}</div>
          </div>
        </div>
      </div>
      <div v-if="!loaded"  class="d-flex flex-nowrap align-items-center overflow-hidden mb-5">
        <!--begin::Symbol-->
        <div class="symbol symbol-40 flex-shrink-0 mr-3">
          <div class="symbol-label"><PuSkeleton/></div>
        </div>
        <!--end::Symbol-->
        <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
          <div class="white-space-nowrap">
            <span class="text-dark font-weight-bolder mb-1 font-size-lg mr-2"><PuSkeleton/></span>
            <font-awesome-icon icon="caret-right" class="text-muted mr-2"/>
            <span class="text-success font-weight-bolder"><PuSkeleton/></span>
          </div>
          <div class="text-muted font-weight-bolder"><PuSkeleton/></div>
        </div>
      </div>
      <!--end::Widget Item-->
      <!--end::Items-->
      <div v-if="loaded" class="mt-auto">
        <span @click="showPassRequestModal = true"
              class="btn btn-block btn-primary">
          {{ $t('dashboard.ordered_passes.order_pass') }}
        </span>
        <router-link to="/passes" class="btn btn-block btn-light-primary">
          {{ $t('dashboard.ordered_passes.all_reservations') }}
        </router-link>
      </div>
    </div>
    <!--end::Body Active-->
    <!--begin::Body InActive-->
    <div v-else class="card-body d-flex flex-column pt-0">
      <!--begin::Short Info-->
      <p v-html="$t('dashboard.ordered_passes.inactive')"></p>
      <div class="mt-auto">
        <span @click="showPassRequestModal = true"
              class="btn btn-block btn-primary">
          {{ $t('dashboard.ordered_passes.order_pass') }}
        </span>
        <router-link to="/passes" class="btn btn-block btn-light-primary">
          {{ $t('dashboard.ordered_passes.all_reservations') }}
        </router-link>
      </div>
      <!--end::Short Info-->
    </div>
    <!--end::Body InActive-->
    <!--begin::Order Pass Modal-->
    <OrderPassModal
        :showPassRequestModal="showPassRequestModal"
        @updatePassRequestModal="updatePassRequestModal"/>
    <!--end::Order Pass Modal-->
  </div>
  <!--end::Mixed Widget 18-->
</template>

<script>
import {mapGetters} from 'vuex';
import OrderPassModal from "../../../../components/core/OrderPassModal";

export default {
  name: "OrderedPasses",
  components: {
    OrderPassModal
  },
  computed: {
    ...mapGetters({
      locationList: 'locationOrder/list',
      passes: 'dashboard/passes',
    }),
  },
  data() {
    return {
      // passes_list: [],
      loaded: false,
      showPassRequestModal: false,
    }
  },
  methods: {
    getLocationName(locationId) {
      const location = this.locationList.find(item => {
        return item.id === locationId;
      })
      return location.name;
    },
    getTimezone(locationId) {
      const location = this.locationList.find(item => {
        return item.id === locationId;
      })
      return location && location.city ? location.city.timezone : 'UTC';
    },
    updatePassRequestModal(value) {
      this.showPassRequestModal = value;
    }
  },
  watch: {
    passes: {
      handler() {
        if (this.passes.length > 0) {
          // for (let i in this.passes) {
          //   this.passes_list.push({
          //     id: this.passes[i].id,
          //     name: this.passes[i].room.name,
          //     photo: this.passes[i].room.photos.length > 0 ? this.passes[i].room.photos[0] : null,
          //     location_name: this.passes[i].location.name,
          //     location_id: this.passes[i].location.id,
          //     ts_start: this.passes[i].ts_start,
          //     ts_end: this.passes[i].ts_end,
          //   })
          // }
        }
        this.loaded = true
      },
      deep: true,
    },
  },
}
</script>

<style>

</style>
